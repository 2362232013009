@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
	--color-high: #000;
	--flowkitblue: rgba(0, 153, 255, 1);
	--flowkitcharcoal: rgba(34, 34, 34, 1);
	--flowkitgreen: rgba(41, 204, 106, 1);
	--flowkitpurple: rgba(123, 97, 255, 1);
	--flowkitred: rgba(252, 85, 85, 1);
	--flowkitwhite: rgba(255, 255, 255, 1);
	--basic-typography-caption-font-family: "PublicSans-Regular", Helvetica;
	--basic-typography-caption-font-size: 11px;
	--basic-typography-caption-font-style: normal;
	--basic-typography-caption-font-weight: 400;
	--basic-typography-caption-letter-spacing: 0px;
	--basic-typography-caption-line-height: 14px;
	--basic-typography-delete-font-family: "PublicSans-Regular", Helvetica;
	--basic-typography-delete-font-size: 15px;
	--basic-typography-delete-font-style: normal;
	--basic-typography-delete-font-weight: 400;
	--basic-typography-delete-letter-spacing: 0px;
	--basic-typography-delete-line-height: 24px;
	--basic-typography-display-1-font-family: "PublicSans-Medium", Helvetica;
	--basic-typography-display-1-font-size: 64px;
	--basic-typography-display-1-font-style: normal;
	--basic-typography-display-1-font-weight: 500;
	--basic-typography-display-1-letter-spacing: 0px;
	--basic-typography-display-1-line-height: 84px;
	--basic-typography-display-2-font-family: "PublicSans-Medium", Helvetica;
	--basic-typography-display-2-font-size: 56px;
	--basic-typography-display-2-font-style: normal;
	--basic-typography-display-2-font-weight: 500;
	--basic-typography-display-2-letter-spacing: 0px;
	--basic-typography-display-2-line-height: 78px;
	--basic-typography-display-3-font-family: "PublicSans-Medium", Helvetica;
	--basic-typography-display-3-font-size: 48px;
	--basic-typography-display-3-font-style: normal;
	--basic-typography-display-3-font-weight: 500;
	--basic-typography-display-3-letter-spacing: 0px;
	--basic-typography-display-3-line-height: 68px;
	--basic-typography-display-4-font-family: "PublicSans-Medium", Helvetica;
	--basic-typography-display-4-font-size: 32px;
	--basic-typography-display-4-font-style: normal;
	--basic-typography-display-4-font-weight: 500;
	--basic-typography-display-4-letter-spacing: 0px;
	--basic-typography-display-4-line-height: 46px;
	--basic-typography-h1-heading-font-family: "PublicSans-Medium", Helvetica;
	--basic-typography-h1-heading-font-size: 38px;
	--basic-typography-h1-heading-font-style: normal;
	--basic-typography-h1-heading-font-weight: 500;
	--basic-typography-h1-heading-letter-spacing: 0px;
	--basic-typography-h1-heading-line-height: 52px;
	--basic-typography-h2-heading-font-family: "PublicSans-Medium", Helvetica;
	--basic-typography-h2-heading-font-size: 32px;
	--basic-typography-h2-heading-font-style: normal;
	--basic-typography-h2-heading-font-weight: 500;
	--basic-typography-h2-heading-letter-spacing: 0px;
	--basic-typography-h2-heading-line-height: 44px;
	--basic-typography-h3-heading-font-family: "PublicSans-Medium", Helvetica;
	--basic-typography-h3-heading-font-size: 26px;
	--basic-typography-h3-heading-font-style: normal;
	--basic-typography-h3-heading-font-weight: 500;
	--basic-typography-h3-heading-letter-spacing: 0px;
	--basic-typography-h3-heading-line-height: 36px;
	--basic-typography-h4-heading-font-family: "PublicSans-Medium", Helvetica;
	--basic-typography-h4-heading-font-size: 22px;
	--basic-typography-h4-heading-font-style: normal;
	--basic-typography-h4-heading-font-weight: 500;
	--basic-typography-h4-heading-letter-spacing: 0px;
	--basic-typography-h4-heading-line-height: 30px;
	--basic-typography-h5-heading-font-family: "PublicSans-Medium", Helvetica;
	--basic-typography-h5-heading-font-size: 18px;
	--basic-typography-h5-heading-font-style: normal;
	--basic-typography-h5-heading-font-weight: 500;
	--basic-typography-h5-heading-letter-spacing: 0px;
	--basic-typography-h5-heading-line-height: 24px;
	--basic-typography-h6-heading-font-family: "PublicSans-Medium", Helvetica;
	--basic-typography-h6-heading-font-size: 15px;
	--basic-typography-h6-heading-font-style: normal;
	--basic-typography-h6-heading-font-weight: 500;
	--basic-typography-h6-heading-letter-spacing: 0px;
	--basic-typography-h6-heading-line-height: 21px;
	--basic-typography-italicized-font-family: "PublicSans-Regular", Helvetica;
	--basic-typography-italicized-font-size: 15px;
	--basic-typography-italicized-font-style: normal;
	--basic-typography-italicized-font-weight: 400;
	--basic-typography-italicized-letter-spacing: 0px;
	--basic-typography-italicized-line-height: 24px;
	--basic-typography-paragraph-bold-font-family: "PublicSans-Bold", Helvetica;
	--basic-typography-paragraph-bold-font-size: 15px;
	--basic-typography-paragraph-bold-font-style: normal;
	--basic-typography-paragraph-bold-font-weight: 700;
	--basic-typography-paragraph-bold-letter-spacing: 0px;
	--basic-typography-paragraph-bold-line-height: 22px;
	--basic-typography-paragraph-font-family: "PublicSans-Regular", Helvetica;
	--basic-typography-paragraph-font-size: 15px;
	--basic-typography-paragraph-font-style: normal;
	--basic-typography-paragraph-font-weight: 400;
	--basic-typography-paragraph-lead-font-family: "PublicSans-Regular", Helvetica;
	--basic-typography-paragraph-lead-font-size: 18px;
	--basic-typography-paragraph-lead-font-style: normal;
	--basic-typography-paragraph-lead-font-weight: 400;
	--basic-typography-paragraph-lead-letter-spacing: 0px;
	--basic-typography-paragraph-lead-line-height: 28px;
	--basic-typography-paragraph-letter-spacing: 0px;
	--basic-typography-paragraph-line-height: 22px;
	--basic-typography-paragraph-medium-font-family: "PublicSans-Medium", Helvetica;
	--basic-typography-paragraph-medium-font-size: 15px;
	--basic-typography-paragraph-medium-font-style: normal;
	--basic-typography-paragraph-medium-font-weight: 500;
	--basic-typography-paragraph-medium-letter-spacing: 0px;
	--basic-typography-paragraph-medium-line-height: 22px;
	--basic-typography-paragraph-semi-bold-font-family: "PublicSans-SemiBold", Helvetica;
	--basic-typography-paragraph-semi-bold-font-size: 15px;
	--basic-typography-paragraph-semi-bold-font-style: normal;
	--basic-typography-paragraph-semi-bold-font-weight: 600;
	--basic-typography-paragraph-semi-bold-letter-spacing: 0px;
	--basic-typography-paragraph-semi-bold-line-height: 22px;
	--basic-typography-paragraph-small-bold-font-family: "PublicSans-Bold", Helvetica;
	--basic-typography-paragraph-small-bold-font-size: 13px;
	--basic-typography-paragraph-small-bold-font-style: normal;
	--basic-typography-paragraph-small-bold-font-weight: 700;
	--basic-typography-paragraph-small-bold-letter-spacing: 0px;
	--basic-typography-paragraph-small-bold-line-height: 20px;
	--basic-typography-paragraph-small-font-family: "PublicSans-Regular", Helvetica;
	--basic-typography-paragraph-small-font-size: 13px;
	--basic-typography-paragraph-small-font-style: normal;
	--basic-typography-paragraph-small-font-weight: 400;
	--basic-typography-paragraph-small-letter-spacing: 0px;
	--basic-typography-paragraph-small-line-height: 20px;
	--basic-typography-paragraph-small-medium-font-family: "PublicSans-Medium", Helvetica;
	--basic-typography-paragraph-small-medium-font-size: 13px;
	--basic-typography-paragraph-small-medium-font-style: normal;
	--basic-typography-paragraph-small-medium-font-weight: 500;
	--basic-typography-paragraph-small-medium-letter-spacing: 0px;
	--basic-typography-paragraph-small-medium-line-height: 14px;
	--basic-typography-underline-font-family: "PublicSans-Regular", Helvetica;
	--basic-typography-underline-font-size: 16px;
	--basic-typography-underline-font-style: normal;
	--basic-typography-underline-font-weight: 400;
	--basic-typography-underline-letter-spacing: 0px;
	--basic-typography-underline-line-height: 24px;
	--button-1-button-lg-font-family: "PublicSans-Medium", Helvetica;
	--button-1-button-lg-font-size: 17px;
	--button-1-button-lg-font-style: normal;
	--button-1-button-lg-font-weight: 500;
	--button-1-button-lg-letter-spacing: 0.4300000071525574px;
	--button-1-button-lg-line-height: 22px;
	--button-2-button-default-font-family: "PublicSans-Medium", Helvetica;
	--button-2-button-default-font-size: 15px;
	--button-2-button-default-font-style: normal;
	--button-2-button-default-font-weight: 500;
	--button-2-button-default-letter-spacing: 0.4300000071525574px;
	--button-2-button-default-line-height: 18px;
	--button-3-button-small-font-family: "PublicSans-Medium", Helvetica;
	--button-3-button-small-font-size: 13px;
	--button-3-button-small-font-style: normal;
	--button-3-button-small-font-weight: 500;
	--button-3-button-small-letter-spacing: 0.4300000071525574px;
	--button-3-button-small-line-height: 16px;
	--danger-danger-default: 0px 4px 16px 0px rgba(234, 84, 85, 0.45);
	--danger-danger-LG: 0px 10px 20px 0px rgba(234, 84, 85, 0.4);
	--danger-danger-SM: 0px 2px 3px 0px rgba(234, 84, 85, 0.3);
	--form-input-1-label-lg-font-family: "PublicSans-Regular", Helvetica;
	--form-input-1-label-lg-font-size: 18px;
	--form-input-1-label-lg-font-style: normal;
	--form-input-1-label-lg-font-weight: 400;
	--form-input-1-label-lg-letter-spacing: 0px;
	--form-input-1-label-lg-line-height: normal;
	--form-input-2-label-default-font-family: "PublicSans-Regular", Helvetica;
	--form-input-2-label-default-font-size: 15px;
	--form-input-2-label-default-font-style: normal;
	--form-input-2-label-default-font-weight: 400;
	--form-input-2-label-default-letter-spacing: 0px;
	--form-input-2-label-default-line-height: normal;
	--form-input-3-label-sm-font-family: "PublicSans-Regular", Helvetica;
	--form-input-3-label-sm-font-size: 13px;
	--form-input-3-label-sm-font-style: normal;
	--form-input-3-label-sm-font-weight: 400;
	--form-input-3-label-sm-letter-spacing: 0px;
	--form-input-3-label-sm-line-height: normal;
	--form-input-4-input-lg-font-family: "PublicSans-Regular", Helvetica;
	--form-input-4-input-lg-font-size: 18px;
	--form-input-4-input-lg-font-style: normal;
	--form-input-4-input-lg-font-weight: 400;
	--form-input-4-input-lg-letter-spacing: 0px;
	--form-input-4-input-lg-line-height: normal;
	--form-input-5-input-default-font-family: "PublicSans-Regular", Helvetica;
	--form-input-5-input-default-font-size: 15px;
	--form-input-5-input-default-font-style: normal;
	--form-input-5-input-default-font-weight: 400;
	--form-input-5-input-default-letter-spacing: 0px;
	--form-input-5-input-default-line-height: normal;
	--form-input-6-input-sm-font-family: "PublicSans-Regular", Helvetica;
	--form-input-6-input-sm-font-size: 13px;
	--form-input-6-input-sm-font-style: normal;
	--form-input-6-input-sm-font-weight: 400;
	--form-input-6-input-sm-letter-spacing: 0px;
	--form-input-6-input-sm-line-height: normal;
	--form-input-7-placeholder-lg-font-family: "HelveticaNeue-Regular", Helvetica;
	--form-input-7-placeholder-lg-font-size: 18px;
	--form-input-7-placeholder-lg-font-style: normal;
	--form-input-7-placeholder-lg-font-weight: 400;
	--form-input-7-placeholder-lg-letter-spacing: 0px;
	--form-input-7-placeholder-lg-line-height: 22px;
	--form-input-8-placeholder-default-font-family: "PublicSans-Regular", Helvetica;
	--form-input-8-placeholder-default-font-size: 15px;
	--form-input-8-placeholder-default-font-style: normal;
	--form-input-8-placeholder-default-font-weight: 400;
	--form-input-8-placeholder-default-letter-spacing: 0px;
	--form-input-8-placeholder-default-line-height: 24px;
	--form-input-9-placeholder-sm-font-family: "PublicSans-Regular", Helvetica;
	--form-input-9-placeholder-sm-font-size: 13px;
	--form-input-9-placeholder-sm-font-style: normal;
	--form-input-9-placeholder-sm-font-weight: 400;
	--form-input-9-placeholder-sm-letter-spacing: 0px;
	--form-input-9-placeholder-sm-line-height: 21px;
	--shadow-card: 0px 4px 18px 0px rgba(75, 70, 92, 0.1);
	--shadow-card-danger: 0px 4px 18px 0px rgba(234, 84, 85, 0.24);
	--shadow-gray: 0px 4px 16px 0px rgba(165, 163, 174, 0.45);
	--shadow-gray-LG: 0px 10px 20px 0px rgba(165, 163, 174, 0.4);
	--shadow-gray-SM: 0px 2px 4px 0px rgba(165, 163, 174, 0.3);
	--shadow-modal: 0px 5px 20px 0px rgba(75, 70, 92, 0.4);
	--shadow-info-default: 0px 4px 16px 0px rgba(0, 207, 232, 0.45);
	--shadow-info-LG: 0px 10px 20px 0px rgba(0, 207, 232, 0.4);
	--shadow-info-SM: 0px 2px 3px 0px rgba(0, 207, 232, 0.3);
	--menu-active-1-menu-active-font-family: "PublicSans-Regular", Helvetica;
	--menu-active-1-menu-active-font-size: 15px;
	--menu-active-1-menu-active-font-style: normal;
	--menu-active-1-menu-active-font-weight: 400;
	--menu-active-1-menu-active-letter-spacing: 0px;
	--menu-active-1-menu-active-line-height: 23px;
	--shadow-menu-active-shadow: 0px 2px 6px 0px rgba(115, 103, 240, 0.48);
	--shadow-primary-default: 0px 4px 16px 0px rgba(115, 103, 240, 0.45);
	--shadow-primary-LG: 0px 10px 20px 0px rgba(115, 103, 240, 0.4);
	--shadow-primary-SM: 0px 2px 3px 0px rgba(115, 103, 240, 0.3);
	--shadow-secondary-defalut: 0px 4px 16px 0px rgba(168, 170, 174, 0.45);
	--shadow-secondary-LG: 0px 10px 20px 0px rgba(168, 170, 174, 0.4);
	--shadow-secondary-SM: 0px 2px 3px 0px rgba(168, 170, 174, 0.3);
	--shadow-success-default: 0px 4px 16px 0px rgba(40, 199, 111, 0.45);
	--shadow-success-LG: 0px 10px 20px 0px rgba(40, 199, 111, 0.4);
	--shadow-success-SM: 0px 2px 3px 0px rgba(40, 199, 111, 0.3);
	--table-header-1-table-header-font-family: "PublicSans-Medium", Helvetica;
	--table-header-1-table-header-font-size: 13px;
	--table-header-1-table-header-font-style: normal;
	--table-header-1-table-header-font-weight: 500;
	--table-header-1-table-header-letter-spacing: 1px;
	--table-header-1-table-header-line-height: normal;
	--shadow-warning-default: 0px 4px 16px 0px rgba(255, 159, 67, 0.45);
	--shadow-warning-LG: 0px 10px 20px 0px rgba(255, 159, 67, 0.4);
	--shadow-warning-SM: 0px 2px 3px 0px rgba(255, 159, 67, 0.3);
	--opacity-color-danger-16: rgba(234, 84, 85, 0.16);
	--opacity-color-danger-24: rgba(234, 84, 85, 0.24);
	--opacity-color-danger-32: rgba(234, 84, 85, 0.32);
	--opacity-color-danger-40: rgba(234, 84, 85, 0.4);
	--opacity-color-danger-48: rgba(234, 84, 85, 0.48);
	--opacity-color-danger-8: rgba(234, 84, 85, 0.08);
	--opacity-color-dark-16: rgba(75, 75, 75, 0.16);
	--opacity-color-menu-hover: rgba(75, 70, 92, 0.04);
	--opacity-color-menu-open: rgba(75, 70, 92, 0.06);
	--opacity-color-overlay: rgba(75, 70, 92, 0.68);
	--opacity-color-table-striped: rgba(75, 70, 92, 0.03);
	--opacity-color-gray-16: rgba(75, 70, 92, 0.16);
	--opacity-color-gray-24: rgba(75, 70, 92, 0.24);
	--opacity-color-gray-32: rgba(75, 70, 92, 0.32);
	--opacity-color-gray-40: rgba(75, 70, 92, 0.4);
	--opacity-color-gray-48: rgba(75, 70, 92, 0.48);
	--opacity-color-gray-8: rgba(75, 70, 92, 0.08);
	--opacity-color-info-16: rgba(0, 207, 232, 0.16);
	--opacity-color-info-24: rgba(0, 207, 232, 0.24);
	--opacity-color-info-32: rgba(0, 207, 232, 0.32);
	--opacity-color-info-40: rgba(0, 207, 232, 0.4);
	--opacity-color-info-48: rgba(0, 207, 232, 0.48);
	--opacity-color-info-8: rgba(0, 207, 232, 0.08);
	--opacity-color-primary-16: rgba(115, 103, 240, 0.16);
	--opacity-color-primary-24: rgba(115, 103, 240, 0.24);
	--opacity-color-primary-32: rgba(115, 103, 240, 0.32);
	--opacity-color-primary-40: rgba(115, 103, 240, 0.4);
	--opacity-color-primary-48: rgba(115, 103, 240, 0.48);
	--opacity-color-primary-8: rgba(115, 103, 240, 0.08);
	--opacity-color-secondary-16: rgba(168, 170, 174, 0.16);
	--opacity-color-secondary-24: rgba(168, 170, 174, 0.24);
	--opacity-color-secondary-32: rgba(168, 170, 174, 0.32);
	--opacity-color-secondary-40: rgba(168, 170, 174, 0.4);
	--opacity-color-secondary-48: rgba(168, 170, 174, 0.48);
	--opacity-color-secondary-8: rgba(168, 170, 174, 0.08);
	--opacity-color-success-16: rgba(40, 199, 111, 0.16);
	--opacity-color-success-24: rgba(40, 199, 111, 0.24);
	--opacity-color-success-32: rgba(40, 199, 111, 0.32);
	--opacity-color-success-40: rgba(40, 199, 111, 0.4);
	--opacity-color-success-48: rgba(40, 199, 111, 0.48);
	--opacity-color-success-8: rgba(40, 199, 111, 0.08);
	--opacity-color-warning-16: rgba(255, 159, 67, 0.16);
	--opacity-color-warning-24: rgba(255, 159, 67, 0.24);
	--opacity-color-warning-32: rgba(255, 159, 67, 0.32);
	--opacity-color-warning-40: rgba(255, 159, 67, 0.4);
	--opacity-color-warning-48: rgba(255, 159, 67, 0.48);
	--opacity-color-warning-8: rgba(255, 159, 67, 0.08);
	--color-danger: rgba(231, 74, 75, 1);
	--color-danger-100: rgba(250, 216, 216, 1);
	--color-danger-200: rgba(246, 179, 179, 1);
	--color-danger-300: rgba(240, 141, 142, 1);
	--color-danger-400: rgba(235, 107, 108, 1);
	--color-danger-500: var(--color-danger);
	--color-danger-600: rgba(205, 67, 67, 1);
	--color-danger-700: rgba(192, 62, 63, 1);
	--color-danger-800: rgba(179, 59, 60, 1);
	--color-danger-900: rgba(165, 55, 56, 1);
	--background-body: rgba(248, 247, 250, 1);
	--background-card: rgba(255, 255, 255, 1);
	--background-header: rgba(236, 236, 238, 1);
	--color-dark: rgba(75, 75, 75, 1);
	--color-divider: rgba(219, 218, 222, 1);
	--color-facebook: rgba(66, 103, 178, 1);
	--color-google: rgba(219, 68, 55, 1);
	--color-light: rgba(223, 223, 227, 1);
	--color-semi-dark: rgba(36, 39, 69, 1);
	--color-table-striped: rgba(75, 70, 92, 0.02);
	--color-transparent: rgba(255, 255, 255, 0);
	--color-twitter: rgba(29, 161, 242, 1);
	--color-white: rgba(255, 255, 255, 1);
	--color-gray: rgba(155, 152, 163, 1);
	--color-gray-100: rgba(245, 245, 246, 1);
	--color-gray-200: rgba(214, 213, 217, 1);
	--color-gray-300: rgba(194, 192, 199, 1);
	--color-gray-400: rgba(174, 172, 182, 1);
	--color-gray-500: var(--color-gray);
	--color-gray-600: rgba(136, 133, 147, 1);
	--color-gray-700: rgba(118, 114, 130, 1);
	--color-gray-800: rgba(100, 96, 114, 1);
	--color-gray-900: rgba(83, 78, 97, 1);
	--color-info: rgba(0, 200, 229, 1);
	--color-info-100: rgba(197, 243, 249, 1);
	--color-info-200: rgba(142, 233, 245, 1);
	--color-info-300: rgba(91, 222, 239, 1);
	--color-info-400: rgba(45, 212, 234, 1);
	--color-info-500: var(--color-info);
	--color-info-600: rgba(0, 177, 203, 1);
	--color-info-700: rgba(0, 167, 189, 1);
	--color-info-800: rgba(0, 156, 177, 1);
	--color-info-900: rgba(0, 144, 164, 1);
	--color-primary: rgba(104, 92, 238, 1);
	--color-primary-100: rgba(223, 221, 252, 1);
	--color-primary-200: rgba(192, 186, 248, 1);
	--color-primary-300: rgba(161, 154, 245, 1);
	--color-primary-400: rgba(132, 122, 241, 1);
	--color-primary-500: var(--color-primary);
	--color-primary-600: rgba(92, 83, 211, 1);
	--color-primary-700: rgba(87, 78, 197, 1);
	--color-primary-800: rgba(82, 72, 184, 1);
	--color-primary-900: rgba(76, 68, 171, 1);
	--color-secondary: rgba(158, 160, 164, 1);
	--color-secondary-100: rgba(235, 235, 236, 1);
	--color-secondary-200: rgba(215, 216, 218, 1);
	--color-secondary-300: rgba(196, 197, 199, 1);
	--color-secondary-400: rgba(176, 179, 182, 1);
	--color-secondary-500: var(--color-secondary);
	--color-secondary-600: rgba(140, 142, 147, 1);
	--color-secondary-700: rgba(132, 133, 137, 1);
	--color-secondary-800: rgba(123, 125, 128, 1);
	--color-secondary-900: rgba(115, 117, 119, 1);
	--color-success: rgba(36, 192, 100, 1);
	--color-success-100: rgba(206, 242, 222, 1);
	--color-success-200: rgba(159, 230, 189, 1);
	--color-success-300: rgba(115, 216, 159, 1);
	--color-success-400: rgba(73, 204, 129, 1);
	--color-success-500: var(--color-success);
	--color-success-600: rgba(33, 170, 89, 1);
	--color-success-700: rgba(31, 159, 83, 1);
	--color-success-800: rgba(29, 149, 79, 1);
	--color-success-900: rgba(28, 138, 73, 1);
	--color-warning: rgba(255, 149, 59, 1);
	--color-warning-100: rgba(255, 233, 212, 1);
	--color-warning-200: rgba(255, 212, 171, 1);
	--color-warning-300: rgba(255, 189, 131, 1);
	--color-warning-400: rgba(255, 169, 94, 1);
	--color-warning-500: var(--color-warning);
	--color-warning-600: rgba(225, 132, 53, 1);
	--color-warning-700: rgba(212, 124, 50, 1);
	--color-warning-800: rgba(197, 116, 47, 1);
	--color-warning-900: rgba(183, 108, 44, 1);
	--typography-body: rgba(100, 96, 114, 1);
	--typography-heading: rgba(83, 78, 97, 1);
	--typography-muted: rgba(155, 152, 163, 1);
	--typography-placeholder: rgba(174, 172, 182, 1);
}

:root[data-theme="dark"] {
	--color-high: #fff;
	--shadow-card: 0px 4px 20px 0px rgba(15, 20, 34, 0.4);
	--shadow-gray: 0px 4px 16px 0px rgba(15, 20, 34, 0.55);
	--shadow-gray-LG: 0px 10px 20px 0px rgba(15, 20, 34, 0.5);
	--shadow-gray-SM: 0px 2px 4px 0px rgba(15, 20, 34, 0.4);
	--shadow-modal: 0px 5px 20px 0px rgba(15, 20, 34, 0.4);
	--opacity-color-dark-16: rgba(122, 125, 144, 0.16);
	--opacity-color-menu-hover: rgba(134, 146, 208, 0.06);
	--opacity-color-menu-open: rgba(134, 146, 208, 0.1);
	--opacity-color-overlay: rgba(26, 29, 42, 0.68);
	--opacity-color-table-striped: rgba(134, 146, 208, 0.03);
	--opacity-color-gray-16: rgba(134, 146, 208, 0.16);
	--opacity-color-gray-24: rgba(134, 146, 208, 0.24);
	--opacity-color-gray-32: rgba(134, 146, 208, 0.32);
	--opacity-color-gray-40: rgba(134, 146, 208, 0.4);
	--opacity-color-gray-48: rgba(134, 146, 208, 0.48);
	--opacity-color-gray-8: rgba(134, 146, 208, 0.08);
	--background-body: rgba(37, 41, 60, 1);
	--background-card: rgba(47, 51, 73, 1);
	--background-header: rgba(31, 34, 51, 1);
	--color-dark: rgba(215, 216, 222, 1);
	--color-divider: rgba(67, 73, 104, 1);
	--color-light: rgba(68, 71, 91, 1);
	--color-table-striped: rgba(134, 146, 208, 0.02);
	--color-gray: rgba(123, 135, 202, 1);
	--color-gray-100: rgba(227, 230, 245, 1);
	--color-gray-200: rgba(200, 205, 233, 1);
	--color-gray-300: rgba(173, 182, 223, 1);
	--color-gray-400: rgba(160, 170, 217, 1);
	--color-gray-500: var(--color-gray);
	--color-gray-600: rgba(110, 120, 179, 1);
	--color-gray-700: rgba(83, 91, 135, 1);
	--color-gray-800: rgba(65, 70, 103, 1);
	--color-gray-900: rgba(41, 45, 64, 1);
	--typography-body: rgba(173, 182, 223, 1);
	--typography-heading: rgba(200, 205, 233, 1);
	--typography-muted: rgba(110, 120, 179, 1);
	--typography-placeholder: rgba(83, 91, 135, 1);
}

@keyframes horizontal-shaking {
	0% {
		transform: translateX(0)
	}

	25% {
		transform: translateX(5px)
	}

	50% {
		transform: translateX(-5px)
	}

	75% {
		transform: translateX(5px)
	}

	100% {
		transform: translateX(0)
	}
}

input, button, select {
	outline: none;
}

a {
	color: var(--color-primary);
}

.damn {
	color: var(--typography-body);
	/* color: var(--color-primary); */
	background: #000;
	padding: 32px;
}

body {
	margin: 0;
	font-family: 'Public Sans', var(--basic-typography-paragraph-font-family), -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
		'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
		sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	color: var(--typography-body);
	background: var(--background-body);
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
		monospace;
}

.pdfDocument {
	width: min-content;
	height: min-content;
}

.pdfPage {
	width: min-content;
	height: min-content;
}

#selection {
	z-index: 10;
	border: 1px dashed var(--flowkitblue);
	position: absolute;
}

html, body, #root, .App {
	min-height: 100svh;
	height: 100%;
}